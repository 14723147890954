<template>
  <right-side-bar
    v-if="openModal"
    button-class="bg-dynamicBackBtn text-white"
    submit="Save"
    @submit="saveFunction"
    @close="cancel"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Add Supervisory Function
      </p>
    </template>
    <div>
      <div style="height:100%" v-if="loadingData">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else>
        <div>
          <div
            v-for="item in functionDetails"
            :key="item.id"
            class="grid grid-cols-12 gap-7"
            style="margin-top:1.125rem"
          >
            <div class="col-span-6 ">
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Function
                </div>
                <div v-tooltip.bottom="item.name" class="wrapper disabled">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="col-span-6 ">
              <c-select
                placeholder="--Select--"
                :options="functionData.filter(data => data.id !== item.id)"
                variant="w-full"
                label="Supervisory Function"
                v-model="item.reportingTo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </right-side-bar>
</template>

<script>
export default {
  components: {
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    RightSideBar: () => import("@/components/RightSideBar")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingData: true,
      functionDetails: [],
      functionData: [],
      creatingFunction: false,
      hierarchy: [],
      kpiList: [],
      cursor: "",
      disableBtn: false
    };
  },
  methods: {
    reset() {
      this.disableBtn = false;
      this.bgButton = "bg-flame";
      this.cursor = "";
    },
    cancel() {
      if (this.functionId) {
        this.$emit("closeSupervisoryFunctionModal");
      } else {
        this.reset();
        this.$emit("closeSupervisoryFunctionModal");
      }
    },
    saveFunction() {
      const payload = { functions: [] };
      this.functionDetails.forEach(element => {
        if (element.reportingTo === "null") {
          // eslint-disable-next-line no-param-reassign
          element.reportingTo = null;
        }
        payload.functions.push({
          id: element.id,
          reportingTo: element.reportingTo
        });
      });

      this.$_setSupervisoryFunction(payload)
        .then(() => {
          this.$emit("closeSupervisoryFunctionModal");
          this.$toasted.success("Supervisory Function Updated Successfully", {
            duration: 3000
          });
        })
        .catch(error => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
          throw new Error(error);
        });
    },
    editFunction() {
      this.creatingFunction = true;
      const editFunction = {
        functionId: this.functionId,
        name: this.functionCredentials.name,
        costCenter: this.functionCredentials.costCenter,
        hierarchyId: this.functionCredentials.hierarchyId,
        kpis: [],
        reportingTo: null
      };
      this.$_editFunction(editFunction)
        .then(() => {
          this.creatingFunction = false;
          this.$emit("closeFunctionModal");
          this.reset();
        })
        .catch(err => {
          this.creatingFunction = false;
          this.$emit("closeFunctionModal");
          this.reset();
          throw new Error(err);
        });
    },
    getFunctionRoute() {
      this.loadingData = true;
      if (this.functionId) {
        this.$_getFunctionId(this.functionId).then(result => {
          this.functionCredentials = result.data.orgFunction;
          this.kpiList = result.data.orgFunction.kpis;
        });
        this.functionDetails = true;
        this.kpiDetails = true;
      }
      this.getFunction();
    },
    getFunction() {
      this.$_getFunctionKpis("").then(result => {
        this.functionDetails = result.data.functions;
        const supervisoryArray = [...this.functionDetails];
        supervisoryArray.push({ name: "Nil", id: "null" });
        this.functionData = supervisoryArray;
        this.loadingData = false;
      });
    }
  },

  mounted() {
    this.getFunction();
  }
};
</script>

<style scoped>
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
